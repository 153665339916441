import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import TripleColumnBlock from "@components/tripleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import ReactPlayer from 'react-player'

import {
  QuickLinkButton,
} from "@components/buttons"

import {
  LearnMoreButton,
} from "@components/buttons"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const IndexPage = () => {
  return (
    <PageLayout>
      <SEO
        title="Peer Advisory Boards - Marc Borrelli - Websuasion Podcast"
        ogDescription="With 30 years of strategy and investment banking experience, Marc Borrelli is expertly positioned to offer a range of unique advisory services.  He is also currently a Vistage chair."
        image="/images/podcast/season-one/marc-borrelli.jpg"
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
					<h1>Peer Advisory Boards with Marc Borrelli</h1>
					<h3>Vistage Chair</h3>
						<p>
							With 30 years of strategy and investment banking experience, Marc Borrelli is expertly positioned to offer a range of unique advisory services. Across Europe, Africa and the United States, he’s closed more than 100 transactions worth over $3 billion. He is also currently a Vistage chair.
						</p>
						<p>
			        <QuickLinkButton
			          content={"Marc A Borrelli, CFA"}
			          url={"https://www.marcborrelli.com/"}
			          icon={"linkify"}
			        />
						</p>
						<p>
			        <QuickLinkButton
			          content={"Vistage"}
			          url={"https://www.vistage.com/"}
			          icon={"linkify"}
			        />
						</p>
						<p>
							In this episode of Websuasion Conversation, we discuss small business challenges, assessing value, and preparing for sale.
						</p>
					</div>
        }
        textRight={
          <div className="centered">
            <img src="/images/podcast/season-one/marc-borrelli.jpg" className="rounded-square" alt="Peer Advisory Boards with Marc Borrelli" width="300" />
					</div>
        }
      />
			<PageWrapper color="white">
	      <SingleColumnBlock
	        text={
	          <div>
							<h2>Listen Now</h2>
						  <AudioPlayer
						    src="https://websuasion-conversation.s3.amazonaws.com/WebsuasionConversationEpisode028-MarcBorrelli.mp3"
						  />
							<h3>Subscribe to the podcast</h3>
							<p>
								<a href="https://itunes.apple.com/us/podcast/websuasion-conversation/id1454464479" target="_blank">
									<img src="/images/podcast/apple-podcasts-150x150.png" className="icon" alt="Websuasion Conversasion on Apple Podcasts" width="50" />
								</a>
								<a href="https://open.spotify.com/show/521lym6rKJPoWQZbeUROuG" target="_blank">
									<img src="/images/podcast/spotify-150x150.png" className="icon" alt="Websuasion Conversasion on Spotify" width="50" />
								</a>
							</p>
							<p>
								Podcast RSS feed:<br /><br />

								<span className="red">https://websuasion.com/podcast.xml</span>
							</p>
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Introduction</h2>
							<p>
								Marc Borrelli, Vistage Chair, tells us about attending college in England and graduate school in New Orleans.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/PWjlliLUab8'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Improving Businesses</h2>
							<p>
								Marc Borrelli tells us about his current roles as a Vistage Chair and as a business consultant.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/XThGUcHOePA'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>A Difference in Perspective</h2>
							<p>
								Marc talks to us about his reaction when he was first approached about Vistage.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/_iIh1UJ0VtE'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Focus and Accountability</h2>
							<p>
								Marc talks about the benefits of joining Vistage in helping to come to realizations and make difficult decisions.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/KvYw9ZdewlQ'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Setting Your Own Pace</h2>
							<p>
								Marc tells us about the importance of meeting members where they are and allowing them to move forward at their own pace.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/gzrPPZdqqCk'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Business Development</h2>
							<p>
								Marc discusses some of the challenges he faced when he started his own business and what he did to overcome those challenges.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/8bLwXx98rvc'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Technology Association of Georgia</h2>
							<p>
								Marc talks about TAG (Technology Association of Georgia) and the opportunities for those who get involved with the organization.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/ZB7W3YVnuzc'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Vistage Groups</h2>
							<p>
								Marc gives us a brief overview of Vistage, a peer advisory group.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/QwLc0X7IW4k'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Small Business Challenges</h2>
							<p>
								Marc discusses the mistakes that small businesses make and how those mistakes affect them.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/ylgXf9eNFSY'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Having an Exit Plan</h2>
							<p>
								Marc talks about the recommendations he makes to businesses when they are getting ready to sell.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/jCa8Alrh4YU'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Assessing Value</h2>
							<p>
								Marc discusses some of the contributing factors to assessing a company's value and the importance of client relationships.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/oa5lPXaPJsI'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Preparing for Scrutiny</h2>
							<p>
								Marc tells us about the scrutiny a business faces when they are preparing for sale and the importance of being ready for it.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/E_keupyGGCs'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>The Choice to Sell</h2>
							<p>
								Marc talks to us about running your business as though you are always ready to sell and how to pick the ideal time to make the sale.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/Wr7xgPEKVbQ'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>App Developer Checklist</h2>
							<p>
								You've been busy building your business, not learning all the ins and outs of application development. There's no reason you should know what it takes to develop a quality, maintainable application and bring it to market. But, before you contract with any firm (including ours), you should understand what is considered the standard. So, we've developed a handy two-page checklist. It will arm you the questions you need to ask before you move forward.
							</p>
						  <QuickLinkButton
						              content={"Get Your Copy"}
						              url={"/files/developer-checklist.pdf"}
						              icon={"file"}
						            />
	          </div>
	        }
	        textRight={
	          <div>
							<img src="/images/podcast/season-one/app-developer-checklist.png" className="rounded-image" alt="App Developer Checklist" width="620" />
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
	            <h4>Let's Talk!</h4>
	            <p>"Data. Tech. Team." Those are the words we live by at websuasion. Our services foster growth and scalability for your business - all while maintaining your brand. We are an indispensable ally for you as you navigate online marketing. Let's talk. We'd love to know more about what you do and how we can solve your pain points.</p>
	            <RequestMoreInfo />
	          </div>
	        }
	      />
			</PageWrapper>
    </PageLayout>
  )
}

export default IndexPage
